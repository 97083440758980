<template>
  <p>กำลังเข้าสู่ระบบ...</p>
</template>

<script>
import Auth from "@/services/Auth"

export default {
  name: "Callback",
  data() {
    return {
      adminList: ["nuttapong.pongkam@cmu.ac.th", "thapakorn.pe@cmu.ac.th"],
    }
  },
  created() {
    this.filterUser(this.$route.query.code)
  },
  methods: {
    async filterUser(queryCode) {
      let access_token = await Auth.getTokenFromBe(process.env.VUE_APP_REDIRECT_URI, queryCode)
      if (access_token.status !== "fail" && access_token !== "error something") {

        access_token.data[0] = JSON.parse(access_token.data[0])
        let user = await Auth.getUserInGraph(access_token.data[0].access_token)
        if (!(user instanceof Error)) {
          localStorage.setItem("access_token", access_token.data[0].access_token)
          if (user) {
            console.log("user ",user)
            let index = await this.adminList.findIndex((admin) => admin === user["mail"])
            if (index !== -1) {
              localStorage.setItem("permission", "admin")
            } else {
              localStorage.setItem("permission", "user")
            }
            localStorage.setItem("email", user["mail"])
            localStorage.setItem("name", user["displayName"])
            localStorage.setItem("accType", user["jobTitle"])
            if (user["jobTitle"] !== "Employee") {
              this.$router.push({ path: "/pages/nopermission" })
            } else {
              const nextPath = `${localStorage.getItem("beforepath") ? localStorage.getItem("beforepath") : "error"}`
              localStorage.removeItem("beforepath")
              this.$router.push({ path: nextPath })
            }
          } else {
            localStorage.removeItem("access_token")
            localStorage.removeItem("email")
            localStorage.removeItem("name")
            localStorage.removeItem("accType")
            localStorage.removeItem("code")
            localStorage.removeItem("userProfile")
            localStorage.removeItem("permission")
            this.$router.push({ path: "/pages/500" })
          }
        }
      } else {
        this.$router.push({ path: "/pages/500" })
      }
    },
  },
}
</script>
